*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Barlow Condensed", sans-serif;
  overflow-x: hidden;
  line-height: 1.2;
  background-color:#000;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  color: #e2ae39;
}

#root {
  position: relative;
}

.container {
  max-width: 1176px;
}

// font-size

.font-title {
  font-size: 70px;
  @media (max-width: 991px) {
    font-size: 55px;
  }
  @media (max-width: 550px) {
    font-size: 36px;
  }
}

.font-h1 {
  font-size: 65px;
  @media (max-width: 991px) {
    font-size: 50px;
  }
  @media (max-width: 550px) {
    font-size: 30px;
  }
}

.font-h2 {
  font-size: 50px;
  @media (max-width: 991px) {
    font-size: 35px;
  }
  @media (max-width: 550px) {
    font-size: 21px;
  }
}

.font-h3 {
  font-size: 45px;
  @media (max-width: 991px) {
    font-size: 30px;
  }
}

.font-h4 {
  font-size: 30px;
  @media (max-width: 991px) {
    font-size: 25px;
  }
  @media (max-width: 550px) {
    font-size: 18px;
  }
}

.font-h5 {
  font-size: 24px;
  @media (max-width: 991px) {
    font-size: 20px;
  }
  @media (max-width: 550px) {
    font-size: 14px;
  }
}

.font-terms {
  font-size: 18px;
  @media (max-width: 550px) {
    font-size: 14px;
  }
}

.font-nav {
  font-size: 21px;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
}

.font-button1 {
  font-size: 40px;
  @media (max-width: 991px) {
    font-size: 30px;
  }
  @media (max-width: 550px) {
    font-size: 24px;
  }
}
.font-button2 {
  font-size: 21px;
  @media (max-width: 550px) {
    font-size: 16px;
  }
}

// font-weight

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 600;
}

.font-normal {
  font-weight: 500;
}

.font-light {
  font-weight: 400;
}
