.loading-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  .mdcc-logo {
    width: 442px;
    @media (max-width: 991px) {
      width: 179px;
    }
  }
}
