.check-allocation{
    display:flex;
    justify-content: center;
    background-color: black;
    padding: 50px;

    text-align: center;
    color: white;
    .title-panel{
        font-family: 'Barlow Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 70px;
        line-height: 84px; 
        margin-bottom: 34px;
        @media (max-width: 550px) {
            font-size: 32px;
            line-height: 38px; 
            margin-bottom: 14px;
        }   
    }
    .subtitle-panel{
        font-family: 'Barlow Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        @media (max-width: 550px) {
            font-size: 20px;
            line-height: 24px;
        }   
    }
    .table-panel{
        margin-top: 33px;
        margin-bottom: 71px;

        width: 718px;
        background-color: white;
        
        @media (max-width: 718px) {
            width: 100%;
            
        }
        @media (max-width: 550px) {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 31px;
        }
        .table-allocation{
            background-color: black;
            color: white;            
            thead{
                font-family: 'Barlow Condensed';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 38px;
                text-align: center;
                text-transform: uppercase;
                @media (max-width: 550px) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            tbody{
                font-family: 'Barlow Condensed';
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 25px;
                text-transform: uppercase;
                tr{
                    .wl{
                        font-family: 'Barlow Condensed';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 25px;
                        text-align: center;
                        text-transform: uppercase;

                        color: #85FF10;                        
                    }
                    .wl-detail{
                        font-family: 'Barlow Condensed';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 25px;
                        text-align: center;
                        text-transform: uppercase;                        
                    }

                }
                @media (max-width: 550px) {
                    font-size: 14px;
                    line-height: 16px;
                    .wl{
                        font-size: 14px;
                        line-height: 16px;
                    }
                    .wl-detail{
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }


        }
    }
}