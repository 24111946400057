.mint-process{
    background-color: black;
    display: flex;
    justify-content: center;
    img{
        max-width: 1080px;
        @media (max-width: 1080px) {
            padding: 50px;
            width: 100%;
        }
        @media (max-width: 550px) {
            width: 100%;
            padding: 30px;
        }
    }
    .title{
        font-family: 'Barlow Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 70px;
        line-height: 84px;
        text-align: center;    
        color: white;    
        margin-top: 20px;
        margin-bottom: 51px;
        @media (max-width: 760px) {
            font-size: 48px;
            margin-bottom: 50px;
        }
        @media (max-width: 550px) {
            font-size: 32px;
            line-height: 34px;
            margin-bottom: 25px;
        }
    }
}