.footer {
  padding: 95px 0 127px 0;
  background-color: #000;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
}
